import React, { Component } from 'react'
import axios from 'axios'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'

import certificate_img from './img/certificate.svg'
import ribbon_img from './img/ribbon.svg'
import download_img from './img/download.svg'
import im_logo from './img/IM_logo.svg'
import login_img from './img/login.svg'
import brand_logo_img from './img/brand_logo.png'
import ceo from './img/sign_ceo.svg'
import ra from './img/sign_ra.svg'
import ks from './img/sign_ks.svg'
import verify_button_img from './img/verify_button.svg'
import device_img from './img/device.svg'
import not_found_img from './img/not_found.svg'

import './style.css'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.pdf = React.createRef()
        this.state = {
            curr_certificate_id: '',
            certificate_id: null,
            certificate: null,
            annotator_detail: null,
            error: false,
            verifying: false
        }
    }

    componentDidMount = () => this.set_certificates()

    set_certificates() {
        axios.get(`https://certifications-api.intelligentmachines.xyz/certificate/${this.props.match.params.id}/`)
            .then(res => this.setState({
                certificate: res.data,
                verifying: false
            }))
            .catch(e => this.setState({
                error: true,
                verifying: false
            }));
    }

    generatePDF = () => {

        html2canvas(this.pdf.current, {
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
            scale: 2,
            logging: true,
        })
            .then(function (canvas) {
                var wid
                var hgt
                var img = canvas.toDataURL("image/png", 1.0);

                var doc = new jsPDF('l', 'pt', [843, 605]);
                var width = doc.internal.pageSize.getWidth();
                var height = doc.internal.pageSize.getHeight();
                doc.addImage(img, 'PNG', 0, 0, 843, 605);
                // doc.save('im_certificate.pdf');
                window.open(doc.output('bloburl'))
            })
            .then(() => this.setState({ downloading: false }))

    }

    render() {
        return (
            <div className="container">
                {/* <nav className="nav">
                    <div className="container">
                        <img className="brand-logo" src={brand_logo_img} />
                        {this.state.certificate && <button className="verify-another" onClick={() => this.setState({ certificate: null, certificate_id: null, error: false })}><img className="verify-another-image" src={verify_button_img} alt="verify another" />Verify Another</button>}
                    </div>
                </nav> */}
                {/* {this.state.certificate === null ?
                    <div className="container--dashboard">
                        <div className="card card--anotator">
                            {this.state.error ?
                                <div className="error">
                                    <img className="error__image" src={not_found_img} alt="Not Found" />
                                    <div className="error__msg">
                                        <div className="msg msg-1">
                                            Not Found!
                                        </div>
                                        <div className="msg msg-2">
                                            We could not find any certificate with the verification code you entered. Please try again.
                                    </div>
                                    </div>
                                </div> :
                                <div className="level">
                                    <img src={login_img} alt="login" className="level__image" />

                                    <div className="level__msg">
                                        <div className="msg msg-1">
                                            Verify Certificate
                                        </div>
                                        <div className="msg msg-2">
                                            Please enter the cerification code below
                                        </div>
                                    </div>
                                </div>
                            }
                            <input
                                onChange={(e) => this.setState({
                                    curr_certificate_id: e.target.value,
                                    error: null
                                })}
                                type="text"
                                className="input--certificate_id"
                                value={this.state.curr_certificate_id}
                                placeholder="verification code"
                            />
                            <button
                                onClick={() =>
                                    this.setState({
                                        certificate_id: this.state.curr_certificate_id,
                                        verifying: true
                                    },
                                        () => {
                                            this.set_certificates()
                                        })
                                }
                                className={`button${this.state.verifying ? '__verifying' : '__verify'}`}
                                disabled={this.state.downloading ? true : false}
                            >
                                <img src={verify_button_img} />  Verify
                            </button>
                        </div>
                    </div>
                    :
 */}

                <div className="container--certificate">
                    {this.state.certificate &&
                        <>
                            <div className="wranning">
                                <img className="wranning__image" src={device_img} alt="warnning" />
                                <div className="wranning__msg">
                                    <p>We are not able to load the certificate on this device,</p>
                                    <p>But you can download it</p>
                                </div>
                            </div>
                            < div
                                style={{
                                    backgroundImage: `url(${certificate_img})`
                                }}
                                className="card--certificate-pdf"
                                ref={this.pdf}
                            >
                                <div className="description">
                                    <div className="description__body">

                                        <div className="wrapper__12">
                                            <div className="wrapper__1">
                                                <img src={ribbon_img} alt="ribbon" />
                                            </div>
                                            <div className="wrapper__2">
                                                <div className="wrapper__top">
                                                    <img src={im_logo} alt="logo" />
                                                    <div className="headline">
                                                        Certificate of Appreciation
                                                        </div>
                                                </div>

                                                <div className="wrapper__middle">
                                                    <div className="proud">
                                                        Proudly Presented To
                                                        </div>
                                                    <div className="name"> {this.state.certificate.annotator.name} </div>
                                                    <div className="appreciation">
                                                        For your tireless efforts and dedication as an
                                                                <span> AI Apprentice </span> at
                                                                <span>Intelligent Machines. </span>
                                                        We wish you all the success in your life.
                                                        </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wrapper__bottom">
                                            <div className="date">
                                                {moment(this.state.certificate.issue_date).format("DD MMM YYYY")}
                                            </div>

                                            <div className="auth">
                                                <img src={ceo} alt="ceo" />
                                                <div className="name">
                                                    Mohammad Oli Ahad
                                                        </div>
                                                <div className="deg">
                                                    Founder & CEO
                                                        </div>
                                            </div>

                                            <div className="auth">
                                                <img src={ks} alt="ks" />
                                                <div className="name">
                                                    Khaled Syfullah
                                                        </div>
                                                <div className="deg">
                                                    Head of Product
                                                        </div>
                                            </div>

                                            <div className="auth">
                                                <img src={ra} alt="ra" />
                                                <div className="name">
                                                    Rafi Ahmed
                                                        </div>
                                                <div className="deg">
                                                    Product Manager
                                                        </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="description__footer">
                                        <div className="reg">This certificate can be verified at <span> certifications.intelligentmachines.xyz/verify </span> using the verification code: <span>{this.props.match.params.id}</span></div>
                                    </div>
                                </div>

                            </div>
                        </>}
                    <div className="button-group">{this.state.certificate &&

                        <button
                            onClick={() => {
                                this.setState({ downloading: true })
                                this.generatePDF()
                            }}
                            type="primary"
                            className={`button${this.state.downloading ? '__downloading' : '__download'}`}
                            disabled={this.state.downloading ? true : false}
                        >
                            <img src={download_img} alt="=download" /> Download
                            </button>
                    }</div>
                </div>
                {/* } */}
            </div>
        )
    }
}

export default Dashboard