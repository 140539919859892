import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import Dashboard from './Pages/Dashboard'
import Verify from './Pages/Verify'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact component={withRouter(Dashboard)} path="/" />
        <Route component={withRouter(Verify)} path="/verify/:id" />
      </Switch>
    </div>
  );
}

export default App;
